import React, { useEffect, useState } from 'react';
import StarRating from './StarRating';
import styled, { css } from 'styled-components';
import { Col, Flex, Text, Modal, Box, MaskImg, Loader } from '../Ui';
import InputField from '../Ui/Input';
import Button from '../Ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineHeart, AiOutlineShoppingCart } from 'react-icons/ai';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { respondTo } from '../../theme/mixin';
import { ReactComponent as RemoveWishlistIcon } from '../../assets/images/removeWishlistIcon.svg';
import {
  addToWishlist,
  removeFromWishlist,
  addToCart,
  removeFromCart,
  updateQteCart,
} from '../../store/actions/wishlist';
import { ReactComponent as Arrow } from '../../assets/images/arrowDown.svg';
import { getSingleProduct, openOrderModal, closeOrderModal } from '../../store/actions/products';
import ImagesCarousel from '../imagesCarousel';
import Recensione from '../recensione';
import { numberWithDots } from '../../utils/utils';
import useWishlist from '../../hooks/useWishlist';

const Container = styled(Box)`
  margin-bottom: 15px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  //background-color: ${({ theme }) => theme.bg_box};

  ${respondTo.sm`
       grid-template-columns: 1fr 1fr 1fr 1fr;
       align-items: center;
  `};
  ${respondTo.md`
      grid-template-columns: 1fr 2fr 1fr;
       align-items: center;
  `};
`;

const Img = styled.div`
  grid-column: 1/2;
  grid-row: 1/3;
  display: flex;
  justify-content: center;
  height: 157px;
  overflow: hidden;
  cursor: pointer;
  ${({ horiz }) =>
    horiz &&
    css`
      align-items: flex-start;
      width: 33%;
    `};
  ${respondTo.sm`
       
  grid-row: 1/2;
  `};
`;
const BoxInline = styled.div`
  grid-column: 2/3;
  grid-row: 1/2;
  ${respondTo.sm`
       grid-column: 2/3;
 
  `};
`;
const Btns = styled.div`
  grid-column: 1/3;
  grid-row: 3/4;
  svg {
    color: ${({ theme }) => theme.colore_testo_box};
  }
  ${respondTo.sm`
      grid-column: 4/5;
      grid-row: 1/2;
  `};
`;
const Qte = styled.div`
  grid-column: 1/2;
  grid-row: 2/3;
  text-align: center;
  ${respondTo.sm`
    grid-column: 3/4;
    grid-row: 1/2;
  `};
  input {
    width: 80px;
    height: 30px;
    padding: 0 10px;
    margin-top: 10px;
    border: ${({ theme }) => '1px solid ' + theme.bordersColor.btn};
  }
`;
const CustomFlexRating = styled.div`
  grid-column: 2/3;
  grid-row: 2/3;
  ${respondTo.sm`
      
      `};
`;
const AddToCart = styled(MaskImg)`
  width: 35px;
  height: 35px;
  margin: 0;
`;
const AddToWishlist = styled(AddToCart)``;

function ProductInline({
  setpuntiAbbastanza,
  inLine,
  inSlider,
  product,
  inModal,
  horiz,
  setIsReviews,
  has_fee: userHasFee,
  ...restProps
}) {
  const {
    id,
    brand_description,
    catalog_id,
    title,
    macroCategory_description,
    points: productPoint,
    quantity,
    images = [],
    rating,
    reviews,
    cart_quantity,
    points: CustomPoints,
    fee,
    is_salabam,
    type,
    selected_child,
  } = product || {};

  const dispatch = useDispatch();
  const isWishlist = useWishlist();
  const has_fee = useSelector(state => state.app.config.has_fee);

  const wishlist = useSelector(state => state.user.user.wishlist);
  const loading = useSelector(state => state.user.isLoading);
  const pointsLabel = useSelector(state => state.app.config.points_label);
  const cart = useSelector(state => state.user.user.cart);
  const { score, id: userId } = useSelector(state => state.user.user.userinfo || {});
  const [isLoadingCart, setIsLoadingCart] = useState(false);
  const [changeQtyLoading, setChangeQtyLoading] = useState(false);
  const [isLoadingWish, setIsLoadingWish] = useState(false);
  const [points, setPoints] = useState(productPoint);
  const [inWishlist, setInWishlist] = useState(false);
  const [inCart, setInCart] = useState(false);
  const [numRating, setNumRating] = useState(0);
  const [qte, setQte] = useState(cart_quantity || 1);
  const pathName = window.location.pathname;
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});
  const addToWishlistHandler = async productId => {
    setIsLoadingWish(true);
    await dispatch(addToWishlist(productId, catalog_id, selected_child?.id));
    setIsLoadingWish(false);
  };

  const removeFromWishlistHandler = async productId => {
    setIsLoadingWish(true);
    dispatch(closeOrderModal());
    await dispatch(removeFromWishlist(productId, catalog_id));

    setIsLoadingWish(false);
  };

  const moveToWishlistHandler = async productId => {
    setIsLoadingWish(true);
    dispatch(closeOrderModal());
    await dispatch(removeFromCart(productId, catalog_id));
    await dispatch(addToWishlist(productId, catalog_id, selected_child?.id));
    setIsLoadingWish(false);
  };

  const moveToCartHandler = async productId => {
    setIsLoadingCart(true);
    if (score - CustomPoints < 0) {
      setpuntiAbbastanza(true);
      setIsLoadingCart(false);
    } else {
      dispatch(closeOrderModal());
      await dispatch(removeFromWishlist(productId, catalog_id));
      await dispatch(addToCart(productId, catalog_id, selected_child?.id));

      setIsLoadingCart(false);
    }
  };
  useEffect(() => {
    if (wishlist && product) {
      const exist = wishlist.find(el => el.id === product.id);

      if (exist) {
        setInWishlist(true);
      } else {
        setInWishlist(false);
      }
    }
  }, [wishlist, product]);

  useEffect(() => {
    if (cart && product) {
      const exist = cart.find(el => el.id === product.id);
      if (exist) {
        setInCart(true);
      } else {
        setInCart(false);
      }
    }
  }, [cart, product]);
  const handlerModal = () => {
    dispatch(getSingleProduct(id, catalog_id));
    product && dispatch(openOrderModal(product));
  };
  const removeCartHandler = async () => {
    setIsLoadingCart(true);
    await dispatch(removeFromCart(id, catalog_id));
    setIsLoadingCart(false);
  };
  const addCartHandler = async () => {
    //if (userHasFee && cart.length > 0) return;
    setIsLoadingCart(true);
    if (score - CustomPoints < 0) {
      setpuntiAbbastanza(true);
      setIsLoadingCart(false);
    } else {
      dispatch(addToCart(id, catalog_id, selected_child?.id));
      setIsLoadingCart(false);
    }
  };
  const changeQteHandler = e => {
    if ((e.target.value > 0 && e.target.value <= quantity) || e.target.value === '') {
      setQte(e.target.value);
      if (e.target.value !== '') {
        setChangeQtyLoading(true);
        setPoints(+productPoint * e.target.value);
        dispatch(updateQteCart(id, catalog_id, e.target.value, selected_child?.id)).then(() =>
          setChangeQtyLoading(false),
        );
      }
    }
    return;
  };
  const blurHandler = () => {
    //dispatch(updateQteCart(id, catalog_id, qte, selected_child?.id));
  };

  return (
    <Container {...restProps}>
      <Img
        onClick={() => {
          setIsReviews(false);
          handlerModal();
        }}
      >
        <img src={images[0]?.small} />
      </Img>
      <BoxInline>
        <Flex style={{ marginBottom: '10px' }}>
          <Text text_box>{macroCategory_description}</Text>
          <Text margin='0 0 0 5px' text_box>
            {brand_description}
          </Text>
        </Flex>
        <Text bold as='h4' size={13} text_box>
          {title}
        </Text>
        <Flex align='center' style={{ marginTop: '15px' }}>
          {changeQtyLoading ? (
            <Loader show={true} inBtn style={{ height: '22px' }} />
          ) : (
            <Text bold as='h4' text_box>
              {cart_quantity
                ? numberWithDots(+productPoint * cart_quantity)
                : numberWithDots(points)}{' '}
              {pointsLabel}{' '}
              {has_fee &&
                ' + ' +
                  (cart_quantity
                    ? String(
                        parseFloat(fee.replace(/\D+$/, '').replace(',', '.')) * cart_quantity,
                      ).replace('.', ',') + fee.replace(/^[\d,]+/, '')
                    : fee)}
            </Text>
          )}
          {pointsLabel.toLowerCase() !== 'euro' && (
            <Text style={{ marginLeft: '20px' }} text_box>
              Punti mancanti = {score - points > 0 ? 0 : numberWithDots(Math.abs(score - points))}
            </Text>
          )}
        </Flex>

        <Text
          size={14}
          style={{ textDecoration: 'underline', marginTop: '10px', cursor: 'pointer' }}
          primary
          bold
          capit
          onClick={() => {
            setIsReviews(false);
            handlerModal();
          }}
        >
          Descrizione completa
        </Text>
        <CustomFlexRating>
          <Flex align='center' style={{ margin: '15px 0' }}>
            <StarRating
              num={rating}
              numRating={numRating}
              setNumRating={setNumRating}
              inSlider={inSlider}
              readonly={true}
              halfStar={true}
            />
            <Text as='p' style={{ marginLeft: '5px' }} text_box>
              ({reviews.length}){' '}
            </Text>
          </Flex>

          {!reviews?.find(elem => elem.player_id === userId) && (
            <Text
              as='div'
              size={12}
              upper
              bold
              text_box
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsReviews(true);
                !inModal && handlerModal();
              }}
            >
              INVIA RECENSIONE
            </Text>
          )}
        </CustomFlexRating>
      </BoxInline>
      {pathName === '/cart' && type !== 'code' && type !== 'voucher' && !userHasFee && (
        <Qte>
          <Text capit text_box>
            {' '}
            quantita:
          </Text>
          <input
            type='number'
            value={qte}
            onChange={changeQteHandler}
            min='1'
            max={quantity}
            onBlur={blurHandler}
            disabled={changeQtyLoading}
          />
        </Qte>
      )}
      <Btns>
        {!is_salabam && (
          <Button
            size={14}
            padding='0 10px 0 25px'
            style={{ backgroundImage: 'none', marginBottom: '10px' }}
            color='#000000'
            border
            upper
            fullwidth
            loading={isLoadingCart}
            disab
            className='hoverBtn'
            disabled={pathName === '/wishlist' ? false : inWishlist}
            onClick={() => {
              return pathName === '/catalog' || pathName === '/'
                ? inCart
                  ? removeCartHandler()
                  : addCartHandler()
                : pathName === '/wishlist'
                ? moveToCartHandler(id)
                : pathName === '/cart'
                ? removeCartHandler(id)
                : null;
            }}
          >
            <Text as='span' upper type='textSlider' style={{ whiteSpace: 'nowrap' }} text_box>
              {pathName === '/catalog' || pathName === '/'
                ? inCart
                  ? 'Rimuovi dal CARRELLO'
                  : 'AGGIUNGI AL CARRELLO'
                : pathName === '/wishlist'
                ? 'Sposta nel carrello'
                : pathName === '/cart'
                ? 'Rimuovi dal carrello'
                : ''}
            </Text>
            {pathName === '/cart' ? (
              <RiDeleteBin2Line />
            ) : option?.menuSettings?.cart?.icon_url ? (
              <AddToCart className='maskIcon' src={option?.menuSettings?.cart?.icon_url} />
            ) : (
              <AiOutlineShoppingCart />
            )}
          </Button>
        )}

        {isWishlist && (
          <Button
            size={14}
            padding='0 10px 0 25px'
            style={{ backgroundImage: 'none' }}
            color='#000000'
            border
            upper
            className='hoverBtn'
            fullwidth
            loading={isLoadingWish}
            disabled={pathName === '/cart' ? false : inCart}
            onClick={() =>
              pathName === '/catalog' || pathName === '/'
                ? inWishlist
                  ? removeFromWishlistHandler(id)
                  : addToWishlistHandler(id)
                : pathName === '/wishlist'
                ? removeFromWishlistHandler(id)
                : pathName === '/cart'
                ? moveToWishlistHandler(id)
                : null
            }
          >
            <Text
              as='span'
              upper
              type='textSlider'
              style={{ marginRight: ' 10px', whiteSpace: 'nowrap' }}
              text_box
            >
              {pathName === '/catalog' || pathName === '/'
                ? inWishlist
                  ? 'Rimuovi dai preferiti'
                  : 'Aggiungi ai preferiti'
                : pathName === '/wishlist'
                ? 'Rimuovi dai preferiti'
                : pathName === '/cart'
                ? 'Sposta nei preferiti'
                : ''}
            </Text>
            {pathName === '/wishlist' ? (
              <RemoveWishlistIcon style={{ width: '30px' }} />
            ) : option?.menuSettings?.wishlist?.icon_url ? (
              <AddToWishlist className='maskIcon' src={option?.menuSettings?.wishlist?.icon_url} />
            ) : (
              <AiOutlineHeart />
            )}
          </Button>
        )}
      </Btns>
    </Container>
  );
}

export default ProductInline;
